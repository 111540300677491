:root {
    --bold: 'Lato';
    --regular: 'Open Sans';

    --white: #FFF;
    --gray: #EFEFF4;
    --darkGray: #7E8DA6;
    --black: #000;
    --blue: #3366FF;
    --purple: #6B46CB;
}

* {
    margin: 0;
    padding: 0;
    font-family: var(--regular), sans-serif;
    font-size: 1em;
}

button,
input,
textarea,
select {
    background: none;
    outline: none;
    border: none;
}
input,
textarea,
select {
    border: 1px solid var(--gray);
    padding: 8px 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
}
input:focus,
textarea:focus,
select:focus {
    border: 1px solid var(--blue);
}
input::placeholder,
textarea::placeholder {
    color: var(--darkGray);
}
select {
    height: 40px;
}
button {
    cursor: pointer;
}
button:disabled {
    cursor: default;
    opacity: 0.6;
}
textarea {
    resize: none;
}
a {
    text-decoration: none;
    color: var(--blue);
}
i {
    display: block !important;
}
