#Auth .title {
  margin-bottom: 64px;
}
#Auth main {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}
#Auth main input {
  margin-bottom: 8px;
}
#Auth main input:last-of-type {
  margin-bottom: 24px;
}
#Auth main .Button {
  width: 100%;
}
