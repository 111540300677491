#UserDetail {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 128px;
  overflow-y: scroll;
}
#UserDetail .detail {
  background: #FFF;
  padding: 24px;
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
}
#UserDetail .detail header {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--gray);
}
#UserDetail .detail header p {
  font-size: 2em;
  flex: 1;
  font-family: var(--bold);
}
#UserDetail .detail .info {
  padding-bottom: 8px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--gray);
}
#UserDetail .detail footer {
  display: flex;
}
#UserDetail .detail footer .Button {
  flex: 1;
}
#UserDetail .detail footer .Button:first-child {
  margin-right: 24px;
}
