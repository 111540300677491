.AnswerSet {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.AnswerSet > div {
  margin-bottom: 16px;
  width: 100%;
}
.AnswerSet > p {
  font-size: 0.8em;
}
