.SetRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.SetRow p {
  text-align: right;
  width: 24px;
  font-size: 0.8em;
  margin-right: 8px;
}
.SetRow i {
  font-size: 16px;
}
.SetRow i.gray {
  color: var(--gray);
}
.SetRow i.red {
  color: #F00;
}
.SetRow i.green {
  color: #0F0;
}
