.Option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.Option:last-of-type {
  margin-bottom: 16px;
}
.Option button {
  margin-left: 24px;
}
.Option button i {
  color: var(--darkGray);
}
.Option button.true i {
  color: var(--blue);
}
