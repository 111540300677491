#Admin {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--gray);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
#Admin a {
  display: flex;
  align-items: center;
}
#Admin a:hover p {
  text-decoration: underline;
}
#Admin a i {
  font-size: 24px;
  color: var(--black);
  margin-right: 8px;
}
#Admin > p {
  color: var(--darkGray);
}
