#InitForm {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 128px 24px;
  overflow-y: scroll;
}
#InitForm .form {
  background: #FFF;
  padding: 24px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
}
#InitForm .form header {
  display: flex;
  align-items: center;
}
#InitForm .form header p {
  flex: 1;
  font-size: 1.4em;
  font-family: var(--bold);
  color: var(--blue);
}
#InitForm .form main {
  padding: 24px 0;
}
#InitForm .form main input,
#InitForm .form main select {
  margin-bottom: 8px;
}
#InitForm .form main div {
  border: 1px solid var(--gray);
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
}
#InitForm .form main div input {
  border: none;
  margin-bottom: 0;
}
#InitForm .form main div p {
  color: var(--darkGray);
  font-size: 0.8em;
  padding: 0 16px 8px;
}
#InitForm .form main input:last-child,
#InitForm .form main select:last-child {
  margin-bottom: 0;
}
#InitForm .form .Button {
  width: 100%;
}
#InitForm .form > p {
  color: var(--darkGray);
  font-size: 0.8em;
  margin-top: 8px;
}