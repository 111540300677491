#Secret {
  max-width: 800px;
  margin: 0 auto;
  padding: 48px;
}
#Secret > header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--gray);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
#Secret > header div {
  flex: 1;
}
#Secret > header div a {
  color: var(--blue);
  display: inline-flex;
  align-items: center;
}
#Secret > header div a:hover p {
  text-decoration: underline;
}
#Secret > header div p {
  text-align: left;
}
#Secret > header button i {
  color: var(--blue);
  font-size: 32px;
}

#Secret .title {
  font-size: 2em;
  text-align: center;
  font-family: var(--bold);
}

@media (min-width: 600px) {
  #Secret {
    padding: 128px;
  }
}
