#TestPopUp {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 128px;
  overflow-y: scroll;
}
#TestPopUp main {
  background: #FFF;
  padding: 24px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
}
#TestPopUp main p:first-child {
  text-align: center;
  font-size: 1.6em;
  font-family: var(--bold);
  margin-bottom: 24px;
}
#TestPopUp main p:last-of-type {
  text-align: center;
  margin-bottom: 48px;
}
#TestPopUp main a {
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: underline;
}
#TestPopUp main .Button {
  width: 100%;
}
