#Init > header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 96px;
  margin-bottom: 48px;
}
#Init > header img {
  width: 200px;
  display: block;
}
#Init > header img:first-child {
  margin-right: 48px;
}

#Init > p:first-of-type {
  margin-bottom: 24px;
  color: var(--blue);
  font-family: var(--bold);
  font-size: 2em;
  text-align: center;
}
#Init > p:last-of-type {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 64px;
  text-align: center;
}
#Init .stack {
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}
#Init .stack button {
  flex: 1;
}
#Init .stack button:first-child {
  margin-right: 24px;
}