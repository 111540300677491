.Info {
  padding: 8px 16px;
  border: 1px solid var(--gray);
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}
.Info p:first-child {
  font-size: 0.8em;
}
.Info p:last-child {
  font-family: var(--bold);
}
