#ResultSummary > div {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
#ResultSummary > div > div {
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
#ResultSummary > div > div p:first-child {
  font-size: 0.6em;
  margin-right: 8px;
}
#ResultSummary > p {
  font-family: var(--bold);
  font-size: 1.4em;
  text-align: right;
}
