.TestSection {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--gray);
}
.TestSection > p:first-child {
  text-align: center;
  font-family: var(--bold);
  font-size: 1.2em;
  margin-bottom: 8px;
}
.TestSection main {
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;
}
.TestSection > p:last-child {
  font-family: var(--bold);
  font-size: 1.2em;
  text-align: right;
}
