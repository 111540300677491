#MemberForm {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 128px;
  overflow-y: scroll;
}
#MemberForm .form {
  background: #FFF;
  padding: 24px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  margin: 0 auto;
}
#MemberForm .form header {
  display: flex;
  align-items: center;
}
#MemberForm .form header p {
  flex: 1;
  font-size: 1.4em;
}
#MemberForm .form main {
  padding: 24px 0;
}
#MemberForm .form main input {
  margin-bottom: 8px;
}
#MemberForm .form main input:last-child {
  margin-bottom: 0;
}
#MemberForm .form main button {
  display: flex;
  align-items: center;
  color: var(--blue);
  width: 100%;
}
#MemberForm .form main button i {
  margin-right: 8px;
}
#MemberForm .form main button p {
  font-size: 0.8em;
}
#MemberForm .form .Button {
  width: 100%;
}
