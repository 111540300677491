.User {
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 48px 24px;
}
.User i,
.User p {
  text-align: center;
}
.User i {
  font-size: 64px;
  color: var(--gray);
  margin-bottom: 24px;
}
.User p.name {
  font-size: 1.2em;
}
.User p.id,
.User p.status {
  font-size: 0.8em;
}
.User p.id {
  color: var(--darkGray);
  margin-bottom: 24px;
}
.User p.status {
  color: var(--blue);
}
