#App {
  max-width: 800px;
  margin: 0 auto;
  padding: 48px;
}
#App.background {
  overflow: visible;
  background: url(../../assets/Background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 100%;
  min-height: 100vh;
}

@media (min-width: 600px) {
  #App {
    padding: 128px;
  }
}
