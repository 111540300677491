#Answerable header {
  border-bottom: 1px solid var(--gray);
  padding-bottom: 24px;
}
#Answerable header p:first-child {
  margin-bottom: 24px;
  color: var(--blue);
  white-space: pre-line;
}
#Answerable header p:last-child {
  font-size: 1.4em;
  white-space: pre-line;
}
#Answerable header p.large {
  font-size: 1em;
}
#Answerable header p:last-child span {
  font-size: 1.4em;
  background: var(--purple);
  color: #FFF;
  padding: 0 8px;
}
#Answerable main {
  padding: 24px 0;
}
#Answerable main button {
  display: flex;
  margin-bottom: 8px;
}
#Answerable main button i {
  margin-right: 8px;
}
#Answerable main button i.true {
  color: var(--blue);
}
#Answerable footer {
  border-top: 1px solid var(--gray);
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Answerable footer > div {
  flex: 1;
}
#Answerable footer > div p {
  display: flex;
  align-items: center;
}
#Answerable footer > div p:first-child {
  margin-bottom: 8px;
}
#Answerable footer > div p i {
  margin-right: 8px;
  color: var(--blue);
}
#Answerable footer .Button {
  margin-left: 8px;
  width: 200px;
}
