#UserSearch > header {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid var(--gray);
  display: flex;
  align-items: center;
}
#UserSearch > header input {
  border: none;
  padding: 0;
  border-radius: 0;
}
#UserSearch > header button {
  border-left: 1px solid var(--gray);
  margin-left: 16px;
  padding-left: 16px;
}
#UserSearch > header button:disabled {
  color: var(--darkGray);
}
#UserSearch > main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  margin-bottom: 24px;
}
#UserSearch > button {
  margin: 0 auto;
  padding: 24px;
  background: var(--blue);
  border-radius: 100%;
  display: block;
}
#UserSearch > button i {
  color: #FFF;
}
