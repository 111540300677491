@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.Question {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.Question > p {
  width: 48px;
  font-size: 2em;
}
.Question > div {
  flex: 1;
  padding-left: 24px;
  border-left: 1px solid var(--gray);
}
.Question > div > textarea {
  margin-bottom: 8px;
}
.Question .audioInput {
  margin-bottom: 12px;
  border: 1px solid var(--gray);
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.Question .audioInput label {
  cursor: pointer !important;
  flex: 1;
}
.Question .audioInput input {
  border: none;
  padding: 0;
  border-radius: 0;
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.Question .audioInput .saving.false {
  color: var(--blue);
}
.Question .audioInput .saving.true {
  animation: rotate 1s linear infinite;
}
.Question > div > .Button {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
