#Status > p {
  color: var(--blue);
  font-family: var(--bold);
  font-size: 3em;
  text-align: center;
}
#Status > button {
  margin: 0 auto;
  display: block;
  text-decoration: underline;
  color: var(--darkGray);
  margin-bottom: 64px;
}
#Status main > p {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 32px;
}
#Status main > p.title {
  font-family: var(--bold);
  font-size: 28px;
  margin-bottom: 8px;
}
#Status main ol {
  margin-bottom: 64px;
}
#Status main ol li {
  margin-bottom: 8px;
}
#Status main ol li:last-child {
  color: var(--blue);
}
#Status main textarea {
  max-width: 600px;
  margin: 0 auto 48px;
  height: 128px;
  display: block;
}

#Status main div.stack {
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}
#Status main div.stack button {
  flex: 1;
}
#Status main div.stack button:first-child {
  margin-right: 24px;
}

#Status main > button {
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}