.Button {
    background: var(--purple);
    padding: 16px 24px;
    color: var(--white);
    border-radius: 8px;
    transition: 0.2s;
}
.Button.blue {
    background: var(--blue);
}
