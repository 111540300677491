.Member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.Member > div {
  /* flex: 1; */
}
.Member > div:first-child p:last-child {
  color: var(--darkGray);
  font-size: 0.8em;
}
.Member > div:last-child {
  display: flex;
  align-items: center;
}
.Member button {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background: var(--darkGray);
  margin-left: 24px;
}
.Member button.admin.true {
  background: var(--blue);
}
.Member button.delete {
  background: #CF0C41;
}
.Member button i,
.Member button p {
  color: var(--white);
}
.Member button i {
  margin-right: 4px;
  font-size: 16px;
}
.Member button p {
  font-size: 0.8em;
}
